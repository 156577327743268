@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
    --gold: #f8e29c; /* Gold color */
    --purple: #4b0082; /* Purple color */
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
  }
}

body, html {
  height: 100%;
  margin: 0;
}

#backgroundVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100; /* Stay behind other content */
  background-size: cover;
}

.content {
  position: relative;
  z-index: 1;
}

.swiper-pagination-bullet {
  width: 7px !important;
  height: 7px !important;
  background: rgba(255, 255, 255, 0.2);
  scale: 0.9 !important;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: white !important;
  scale: 1 !important ;
}

.agora-font {
  font-family: 'Fredoka', cursive;
}

.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Ensures the video is behind all content */
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
  opacity: 1; /* Ensure the video is fully visible */
}
.hamburger {
  cursor: pointer;
}

.hamburger div {
  transition: all 0.3s ease;
}
.lowercase {
  text-transform: lowercase;
}
/* styles.css or your main CSS file */
.bank-account-form h2 {
  color: #4b0082; /* Example color */
  margin-bottom: 10px;
}

.bank-account-form p {
  color: #f8e29c; /* Example color */
  font-size: 14px;
}
.arrow {
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.answer {
  max-height: 0;
  transition: max-height 0.3s ease;
  overflow: hidden;
}

.answer.show {
  max-height: 200px; /* Adjust based on content */
}
.header {
  font-weight: bold;
  font-size: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

/* Spinner.css */
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; /* Adjust height as needed */
}

.spinner {
  border: 8px solid #f3f3f3; /* Light background */
  border-top: 8px solid #6a0dad; /* Purple color */
  border-radius: 50%;
  width: 40px; /* Size of the spinner */
  height: 40px; /* Size of the spinner */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.home-container {
    position: relative; /* Allow content to be positioned relative to this container */
    width: 100%;
    min-height: 100vh; /* Ensure it covers at least the full height of the viewport */
    overflow: hidden; /* Prevent overflow of the background */
}

.background {
    position: fixed; /* Keep the background fixed */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Cover the entire viewport */
    background-image: url('/public/mobilebg.png'); /* Mobile background */
    background-size: cover;
    background-position: center;
    z-index: -1; /* Ensure it stays behind other content */
}

.content {
    position: relative; /* Ensure content is above the background */
    z-index: 1; /* Bring content above the background */
    padding-bottom: 100px; /* Add padding to ensure content doesn't overlap with footer */
    overflow-y: auto; /* Allow vertical scrolling */
}

@media (min-width: 768px) {
    .home-container {
        background-image: none; /* Remove background for larger screens */
    }
}